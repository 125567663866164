<template>
    <v-app-bar app color="white" dark flat clipped-left>
        <v-app-bar-nav-icon @click="$emit('toggleDrawer')" v-if="isDashboard" />
        <router-link :to="{ name: 'landing' }">
            <div class="d-flex align-center">
               <img src="../assets/logo.svg"  class="shrink mr-2" alt="Logo">
            </div>
        </router-link>
        <div
            style="margin-left:77px;"
            class="black--text text-h5 font-weight-bold"
        >
            {{ $t($route.meta.title) }}
        </div>
        <v-spacer></v-spacer>

        <auth-user-dropdown v-if="$auth.user()" />
        <div v-else>
            <v-btn v-if="currentRouteName === 'login'" class="black--text" text :to="{ name: 'sign-up' }">
                {{ $t('general.sign-up') }}
            </v-btn>
            <v-btn v-else class="black--text" text :to="{ name: 'login' }">
                {{ $t('general.login') }}
            </v-btn>
        </div>
    </v-app-bar>
</template>

<script>
import AuthUserDropdown from "./AuthUserDropdown";

export default {
    name: "top-menu",
    props: {
        isDashboard: {
            default: false,
            type: Boolean
        }
    },
    computed: {
        appName() {
            return process.env.VUE_APP_APP_NAME
                ? process.env.VUE_APP_APP_NAME
                : "Saas";
        },
        currentRouteName() {
            return this.$router.history.current.name ?? ''
        }
    },
    components: { AuthUserDropdown },
    data: function() {
        return {};
    },
};
</script>

<style scoped></style>
